export default function DateEnglish(number) {
  let dia;
  let mes;
  let ano;

  dia = String(number).substr(6);
  mes = String(number).substr(4, 2);
  ano = String(number).substr(0, 4);

  return ano + '-' + mes + '-' + dia;
}
