export default function DateFormat(number) {
  let dia;
  let mes;
  let ano;

  dia = String(number).substring(6, 8);
  mes = String(number).substring(4, 6);
  ano = String(number).substring(0, 4);

  return dia + '/' + mes + '/' + ano;
}
