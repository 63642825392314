
const globaisVar = {
  optCategory: [
    'Geral', 'Brasileirão', 'Premier League', 'Camp. Italiano', 'Camp. Francês',
    'Bundesliga', 'La Liga', 'Seleção', 'NBA', 'NFL', 'Rugby', 'Chuteira', 'Tenis', 'Outras'
  ],
  optSize: [
    'P', 'M', 'G', 'GG', '2GG', '3GG', '4GG'
  ],
  optColor: [
    'Masculino', 'Feminino', 'Infantil'
  ],
}

export { globaisVar };