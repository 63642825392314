<template>
  <div class="sells q-px-md">

    <!-- CARDS -->
    <div class="cards q-mt-md">
      <div class="fluxo responsive full-width" style="height: 100%;">
        <div class="box bg-verde">
          <div class="titleCards bLTR text-h6 text-white">
            Resumo das Vendas
          </div>
          <div class="row bg-verde bA" style="height: 100%;" v-for="resumo in resumoVendas" :key="resumo">

            <div class="qtdade col-3">
              
              <q-card square class="qtdade">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Quantidade
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                    {{ isNaN(resumo.qtdade) ? 0 : resumo.qtdade }}
                    <br v-if="isMobile"/>
                    unidades
                  </div>
                </div>
              </q-card>

            </div>

            <div class="receita col-3">
              
              <q-card square class="receita">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Receita
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ isNaN(resumo.receita) ? 0 : parseFloat(resumo.receita).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </div>
                </div>
              </q-card>

            </div>

            <div class="recebido col-3">

              <q-card square class="recebido">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Recebido
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ isNaN(resumo.recebido) ? 0 : parseFloat(resumo.recebido).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </div>
                </div>
              </q-card>

            </div>

            <div class="arecerber col-3">

              <q-card square class="arecerber">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    À Receber
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold" :class="checkValueToReceived">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ isNaN(resumo.areceber) ? 0 : parseFloat(resumo.areceber).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </div>
                </div>
              </q-card>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="fas fa-cart-plus" label="Inserir Venda" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />

      <q-btn icon="event" label="Selecionar Período" no-caps style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white">
        <q-popup-proxy transition-show="scale" transition-hide="scale" >
          <q-date v-model="datePicker" range :locale="ptBR">
            <q-btn color="negative" label="Cancelar" flat v-close-popup />
            <q-btn color="primary" label="Confirmar" flat v-close-popup @click="onDate" />
          </q-date>
        </q-popup-proxy>
      </q-btn>
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        :visible-columns="visibleCols"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-select
            v-model="visibleCols"
            multiple
            filled
            color="yellow"
            dense
            options-dense
            display-value="Colunas"
            emit-value
            map-options
            dark
            :options="cols"
            option-value="name"
            options-cover
            style="width: 100px;"
          />
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVA VENDA -->
    <q-dialog v-model="modalSells" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO VENDA
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVA VENDA
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">
          <div class="radio row justify-center q-gutter-x-xl q-mt-sm" v-if="!editRow">
            <q-radio v-model="downStok" val="downStok" label="Baixa no Estoque" color="primary" @click="onTracking" />
            <q-radio v-model="downStok" val="toOrder" label="Sob Encomenda" color="primary" @click="onTracking" />
          </div>
          <div v-if="editRow" class="text-center text-h6">
            {{ tipoVenda }}
          </div>

          <!--  QUANDO NÃO HOUVER SELEÇÃO DE NENHUMA OPÇÃO DE VENDA (Baixa Estoque ou Sob Encomenda) -->
          <div v-if="controlTracking === null && !editRow" />

          <!-- SOB ENCOMENDA -->
          <div v-if="controlTracking === true && !editRow">
            <div class="respRowForm q-gutter-y-sm q-mt-sm">
              <q-select
                class="twoFields"
                use-input
                new-value-mode="add"
                input-debounce="0"
                behavior="menu"
                @filter="filterClient"
                v-model="client"
                :options="optionsClient"
                label="Cliente"
                outlined
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <div class="row">
                        Sem resultados para o Cliente procurado! Para adicionar novo Cliente
                        <div class="text-blue-7 cursor-pointer q-ml-xs" @click="onAddCliente">
                          Clique aqui
                        </div>
                      </div>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <q-input class="twoFields" outlined v-model="dtSells" type="date" stack-label label="Data da Venda" />
            </div>
            <div v-for="n in countSales" :key="n" class="q-pa-sm q-my-sm" style="border: 1px solid #E0E0E0">
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-select class="threeFields" v-model="formLiga[n]" :options="categoria" label="Categoria" outlined @blur="onSize(formLiga[n])" />
                <q-input class="threeFields" outlined v-model="formTime[n]" type="text" label="Produto" />
                <q-input class="threeFields" outlined v-model="formModelo[n]" type="text" label="Marca" />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="threeFields" outlined v-model="formQtdade[n]" type="number" label="Quantidade" suffix="unidades" @change="validNumber" />
                <q-input class="threeFields" outlined v-model="formTamanho[n]" type="text" label="Armazenamento" />
                <q-input class="threeFields" v-model="formSexo[n]" label="Cor" outlined />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="FourFields" outlined v-model="formValor[n]" type="number" label="Valor do Produto" prefix="R$" placeholder="0" />
                <q-input class="FourFields" outlined v-model="formEntrada[n]" type="number" label="Entrada" prefix="R$" placeholder="0" />
                <q-input class="FourFields" outlined v-model="formCusto[n]" type="number" label="Custo" prefix="R$" placeholder="0" @change="costShirt(n)" />
                <q-input class="FourFields" outlined v-model="formLucro[n]" type="number" label="Lucro" prefix="R$" placeholder="0" disable />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="twoFields" outlined v-model="formRastreio[n]" type="text" label="Código de Rastreio" />
                <q-select class="twoFields" v-model="formEntrega[n]" :options="['Sim', 'Não']" label="Compra Entregue?" outlined />
              </div>
            </div>
            <q-btn flat no-caps color="info" label="Adicionar mais Vendas" v-model="countSales" @click="countSales++" />
            <div class="respRowForm q-gutter-y-sm q-mt-sm">
              <q-input class="oneFields" outlined v-model="obs" type="textarea" label="Observações" />
            </div>
          </div>

          <!-- BAIXA NO ESTOQUE -->
          <div v-if="controlTracking === false && !editRow">
            <div class="respRowForm q-gutter-y-sm q-mt-sm">
              <q-select
                class="twoFields"
                use-input
                new-value-mode="add"
                input-debounce="0"
                behavior="menu"
                @filter="filterClient"
                v-model="client"
                :options="optionsClient"
                label="Cliente"
                outlined
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <div class="row">
                        Sem resultados para o Cliente procurado! Para adicionar novo Cliente
                        <div class="text-blue-7 cursor-pointer q-ml-xs" @click="onAddCliente">
                          Clique aqui
                        </div>
                      </div>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <q-input class="twoFields" outlined v-model="dtSells" type="date" stack-label label="Data da Venda" />
            </div>
            <div v-for="k in countSales" :key="k" class="q-pa-sm q-my-sm" style="border: 1px solid #E0E0E0">
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input
                  v-model="fEstoque[k]"
                  class="oneFields cursor-pointer"
                  type="text"
                  stack-label
                  outlined
                  @click="openProduct(k)"
                  label="Estoque Disponível"
                  placeholder="Selecione o produto desejado..."
                >
                  <template v-slot:append>
                    <q-icon name="search" color="black" @click="openProduct(k)" />
                  </template>
                </q-input>
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input
                  class="twoFields"
                  outlined
                  v-model="fQtdade[k]"
                  type="number"
                  :rules="[ val => val <= this.maxQuant[k] || 'Quantidade excede o estoque!' ]"
                  label="Quantidade"
                  suffix="unidades"
                  @change="validNumber"
                  @blur="recalcValues(k)"
                />
                <q-input class="twoFields" outlined v-model="fValor[k]" type="number" label="Valor do Produto" prefix="R$" placeholder="0" @blur="recalcValues(k)" />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="threeFields" outlined v-model="fEntrada[k]" type="number" label="Entrada" prefix="R$" placeholder="0" @change="validNumber" />
                <q-input class="threeFields" outlined v-model="fCusto[k]" type="number" label="Custo" prefix="R$" placeholder="0" @change="costShirt(k)" @blur="recalcValues(k)" disable />
                <q-input class="threeFields" outlined v-model="fLucro[k]" type="number" label="Lucro" prefix="R$" placeholder="0" disable />
              </div>
            </div>
            <q-btn flat no-caps color="info" label="Adicionar mais Vendas" v-model="countSales" @click="countSales++" />
            <div class="respRowForm q-gutter-y-sm q-mt-sm">
              <q-input class="oneFields" outlined v-model="obs" type="textarea" label="Observações" />
            </div>
          </div>

          <!-- QUANDO TIVER EDITANDO -->
          <div v-if="editRow">
            <div class="respRowForm q-gutter-y-sm q-mt-sm">
              <q-select
                class="twoFields"
                use-input
                new-value-mode="add"
                input-debounce="0"
                behavior="menu"
                @filter="filterClient"
                v-model="client"
                :options="optionsClient"
                label="Cliente"
                outlined
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      <div class="row">
                        Sem resultados para o Cliente procurado! Para adicionar novo Cliente
                        <div class="text-blue-7 cursor-pointer q-ml-xs" @click="onAddCliente">
                          Clique aqui
                        </div>
                      </div>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <q-input class="twoFields" outlined v-model="dtSells" type="date" stack-label label="Data da Venda" />
            </div>
            <div v-for="n in countSales" :key="n" class="q-pa-sm q-my-sm" style="border: 1px solid #E0E0E0">
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-select class="threeFields" v-model="formLiga[n]" :options="categoria" label="Categoria" outlined @blur="onSize(formLiga[n])" />
                <q-input class="threeFields" outlined v-model="formTime[n]" type="text" label="Produto" />
                <q-input class="threeFields" outlined v-model="formModelo[n]" type="text" label="Marca" />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="threeFields" outlined v-model="formQtdade[n]" type="number" label="Quantidade" suffix="unidades" @change="validNumber" />
                <q-input class="threeFields" outlined v-model="formTamanho[n]" type="text"   label="Armazenamento" />
                <q-input class="threeFields" v-model="formSexo[n]" label="Cor" outlined />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="FourFields" outlined v-model="formValor[n]" type="number" label="Valores dos Produtos" prefix="R$" placeholder="0" @change="validNumber" />
                <q-input class="FourFields" outlined v-model="formEntrada[n]" type="number" label="Entrada" prefix="R$" placeholder="0" @change="validNumber" />
                <q-input class="FourFields" outlined v-model="formCusto[n]" type="number" label="Custo" prefix="R$" placeholder="0" @change="costShirt(n)" disable />
                <q-input class="FourFields" outlined v-model="formLucro[n]" type="number" label="Lucro" prefix="R$" placeholder="0" disable />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm" v-if="controlTracking">
                <q-input class="twoFields" outlined v-model="formRastreio[n]" type="text" label="Código de Rastreio" />
                <q-select class="twoFields" v-model="formEntrega[n]" :options="['Sim', 'Não']" label="Compra Entregue?" outlined />
              </div>
              <div class="respRowForm q-gutter-y-sm q-mt-sm">
                <q-input class="oneFields" outlined v-model="obs" type="textarea" label="Observações" />
              </div>
            </div>
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDelete" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow && emiteComprovante" no-caps color="positive" label="Gerar Comprovante" @click="onComprovante" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA SELECIONAR PRODUTO NA BAIXA DO ESTOQUE -->
    <q-dialog v-model="modalSelectProduct" full-width>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          SELEÇÃO DE ITENS DISPONÍVEIS NO ESTOQUE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <div class="respRowForm row q-gutter-y-sm" style="height: 50vh">
          <div class="text-subtitle2">Selecione o produto desejado:</div>
            <q-scroll-area style="width: 100%; height: 40vh;">
              <q-table
                dense
                class="myTable"
                :rows="combobox"
                :columns="comboboxCols"
                row-key="times"
                :rows-per-page-options="[5]"
                rows-per-page-label="Linhas por página: "
                :filter="comboboxFilter"
                no-results-label="Sem dados a serem mostrados"
                no-data-label="Sem dados a serem mostrados"
                @row-click="onGetProduct"
              >
                <template v-slot:top-left>
                  <q-input filled dense dark v-model="comboboxFilter" placeholder="Pesquisar" style="width: 150px">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
              </q-table>
            </q-scroll-area>
          </div>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="modalSelectProduct = false" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA INSERÇÃO DE ESTOQUE -->
    <q-dialog v-model="modalSTK" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVOS ITEM NO ESTOQUE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-select class="threeFields" v-model="ligaSTK" :options="categoria" label="Categoria" outlined />
            <q-input class="threeFields" outlined v-model="timeSTK" type="text" label="Produto" />
            <q-input class="threeFields" outlined v-model="modeloSTK" type="text" label="Marca" />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="tamanhoSTK" type="number" label="Armazenamento" />
            <q-select class="threeFields" v-model="sexoSTK" :options="optGender" label="Cor" outlined />
            <q-input class="threeFields" outlined v-model="qtdSTK" type="number" label="Quantidade Adquirida" suffix="unidades" @change="onQtd" />
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelSTK" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveSTK" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA INSERÇÃO DE NOVOS CLIENTES -->
    <q-dialog v-model="modalClient" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVO CLIENTE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="newClient" type="text" label="Nome do Cliente" />
            <q-input class="twoFields" outlined v-model="cpf" type="text" label="CPF" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="twoFields" outlined v-model="mail" type="text" label="E-Mail" />
            <q-input class="twoFields" outlined v-model="phone" type="text" label="Celular" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="insta" type="text" label="Instagram" />
            <q-input class="threeFields" outlined v-model="team" type="text" label="Preferência" />
            <q-input class="threeFields" outlined v-model="bday" type="date" stack-label label="Aniversário" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="oneFields" outlined v-model="address" type="text" label="Endereço" />
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelCLI" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveCLI" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import DateFormat from '../../helpers/DateFormat';
import DateEnglish from '../../helpers/DateEnglish';
import FormatCodigo from '../../helpers/Format_Codigo_Venda';
import { globaisVar } from '../../globais/GlobalVar';
import locale from 'quasar/lang/pt-BR';
import { mapActions } from 'vuex';
import { ref } from 'vue';
import { Dialog } from 'quasar';
import { categorys } from '../../globais/Categorys';
import { defaultColor } from '../../helpers/defaultColor';
import rastreio from 'rastreio-promise';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalSells: false,
      filter: null,
      cols: [
        { name: 'recno' ,     align: 'center',    label: 'Código',          field: 'recno',      sortable: true,
          format: val => FormatCodigo(val)
        },
        { name: 'client',     align: 'center',    label: 'Cliente',         field: 'client',     sortable: true },
        { name: 'league',     align: 'center',    label: 'Categoria',            field: 'league',     sortable: true },
        { name: 'team',       align: 'center',    label: 'Produto',            field: 'team',       sortable: true },
        { name: 'model',      align: 'center',    label: 'Marca',          field: 'model',      sortable: true },
        { name: 'sizes',      align: 'center',    label: 'Armazenamento',         field: 'sizes',      sortable: true },
        { name: 'gender',     align: 'center',    label: 'Cor',            field: 'gender',     sortable: true },
        { name: 'qtd',        align: 'center',    label: 'Qtdade',          field: 'qtd',        sortable: true },
        { name: 'sale',       align: 'center',    label: 'Vendido',         field: 'sale',       sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'received',   align: 'center',    label: 'Recebido',        field: 'received',   sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'toreceive',  align: 'center',    label: 'À Receber',       field: 'toreceive',  sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
          classes (value) { return value.toreceive > 0 ? 'text-red text-bold' : 'text-black' }
        },
        { name: 'dtpurchase', align: 'center',    label: 'Data Compra',     field: 'dtpurchase', sortable: true,
          format: val => DateFormat(val)
        },
        { name: 'tracking',   align: 'center',    label: 'Cód. Rastreio',   field: 'tracking',   sortable: true },
        { name: 'rec',        align: 'center',    label: 'Recebido?',       field: 'rec',        sortable: true },
        { name: 'downstok',   align: 'center',    label: 'Baixa Estoque?',  field: 'downstok',   sortable: true },
        { name: 'obs',        align: 'center',    label: 'Observações',     field: 'obs',        sortable: true },
        { name: 'codcontrol', align: 'center',    label: 'Controle',        field: 'codcontrol', sortable: true },
      ],
      rows: [],
      resumoVendas: [],
      visibleCols: ref([
        'recno', 'client', 'league', 'team', 'model', 'sizes', 'gender', 'qtd', 'sale', 
        'received', 'toreceive', 'dtpurchase', 'tracking', 'rec', 'downstok', 'obs'
      ]),
      ptBR: locale.date,
      datePicker: null,
      dt_ini: '19000101',
      dt_fim: '30001231',

      //~> PARA O MODAL
      tipoVenda: null,
      codControl: null,
      formLiga: [],
      formTime: [],
      formModelo: [],
      formSexo: [],
      formTamanho: [],
      formQtdade: [],
      formValor: [],
      formEntrada: [],
      formCusto: [],
      formLucro: [],
      formRastreio: [],
      formEntrega: [],

      fEstoque: [],
      fQtdade: [],
      fValor: [],
      fEntrada: [],
      fCusto: [],
      fLucro: [],
      maxQuant: [],

      // optLeague: globaisVar.optLeague.filter(i => { return i !== 'Geral' }),
      categoria: [],
      tipo: [],
      type: null,
      optSizes: globaisVar.optSize,
      optGender: globaisVar.optColor,
      optEstoque: [],
      options: [], //ref(this.optEstoque),
      optClient: [],
      optionsClient: [],
      combobox: [],
      comboboxClient: [],
      client: null,
      fnClient: null,

      downStok: true,
      dtSells: '',
      recno: null,
      tracking: null,
      custo: null,
      lucro: null,
      purchaseReceived: null,
      controlTracking: null,
      rebuild: true,
      statusFields: true,
      countSales: 1,
      obs: null,

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,
      rowOnEdit: null,
      emiteComprovante: true,

      //~> MODAL PARA INCLUSAO DE ESTOQUE
      modalSTK: false,

      ligaSTK: [],
      timeSTK: null,
      modeloSTK: null,
      tamanhoSTK: [],
      sexoSTK: [],
      qtdSTK: null,

      //~> MODAL PARA INCLUSAO DE CLIENTE
      modalClient: false,

      newClient: null,
      cpf: null,
      phone: null,
      address: null,
      mail: null,
      insta: null,
      team: null,
      bday: null,

      //~> MODAL PARA INSERIR PRODUTO DO ESTOQUE
      comboboxCols: [
        { name: 'liga',     align: 'center',    label: 'Categoria',         field: 'liga',      sortable: true },
        { name: 'times',    align: 'center',    label: 'Produto',           field: 'times',     sortable: true },
        { name: 'modelo',   align: 'center',    label: 'Marca',             field: 'modelo',    sortable: true },
        { name: 'tamanho',  align: 'center',    label: 'Armazenamento',     field: 'tamanho',   sortable: true },
        { name: 'sexo',     align: 'center',    label: 'Cor',               field: 'sexo',      sortable: true },
        { name: 'saldo',    align: 'center',    label: 'Quantidade',        field: 'saldo',     sortable: true },
        { name: 'preco',    align: 'center',    label: 'Preço Sugerido',    field: 'preco',     sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'custo',    align: 'center',    label: 'Custo Unit',        field: 'custo',     sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        }
      ],
      comboboxFilter: null,
      modalSelectProduct: false,
      idxEstoque: null,

      costUnit: [],
      sugestPrice: [],
      recnoSTK: null,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    checkValueToReceived() {
      let classCustom
      if(this.resumoVendas[0]?.areceber > 0) {
        classCustom = 'text-red text-bold'
      } else {
        classCustom = 'text-black'
      }

      return classCustom;
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() { //~> OK
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.client = ''
      this.formLiga = []
      this.formTime = []
      this.formModelo = []
      this.formSexo = []
      this.formTamanho = []
      this.formQtdade = []
      this.formValor = []
      this.formEntrada = []
      this.formCusto = []
      this.formLucro = []
      this.formRastreio = []
      this.formEntrega = []
      this.fEstoque = []
      this.fQtdade = []
      this.fValor = []
      this.fEntrada = []
      this.fCusto = []
      this.fLucro = []
      this.custo = ''
      this.lucro = ''
      this.entrada = ''
      this.downStok = true
      this.dtSells = ''
      this.countSales = 1
      this.obs = ''
      this.codControl = ''

    },
    validFields() { //~> OK
      if(
        this.client === '' || this.client === null ||
        this.dtSells === '' || this.dtSells === null
      ) {
        this.statusFields = false;
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher todos os campos'
        });
      } else {
        this.statusFields = true;
      }
    },
    validNumber() { //~> OK
      if(this.qtdade < 0) { this.qtdade = 0 }
    },
    recalcValues(index) {
      let qtdade = this.fQtdade[index] ? this.fQtdade[index] : this.fQtdade[index] = 1

      this.fValor[index] = parseFloat(qtdade * this.fValor[index]).toFixed(2)
      this.fCusto[index] = parseFloat(qtdade * this.costUnit[index]).toFixed(2)
      this.fLucro[index] = parseFloat(this.fValor[index] - this.fCusto[index]).toFixed(2)
    },
    costShirt(index) { //~> OK
      //~> PARA SOB ENCOMENDA
      if(this.formCusto[index] > 0 && this.formValor[index] > 0) {
        this.formLucro[index] = parseFloat(this.formValor[index] - this.formCusto[index]).toFixed(2)
      }

      //~> PARA BAIXA NO ESOTQUE
      if(this.fCusto[index] > 0 && this.fValor[index] > 0) {
        this.fLucro[index] = parseFloat(this.fValor[index] - this.fCusto[index]).toFixed(2)
      }
    },
    onAdd() { //~> OK
      this.resetForm();
      this.modalSells = true;
      this.controlTracking = null;

      this.codControl = Math.random().toFixed(8);
    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalSells = false;
        this.controlTracking = null;
      })
    },
    async onSave() { //~> OK

      // this.validFields();
      if(!this.statusFields) return '';

      try {
        // let aReceber = parseFloat(this.valTTL - this.entrada)
        let dtVenda = String(this.dtSells).replaceAll('-', '')
        let downSTK
        if(this.downStok === 'toOrder') {
          downSTK = 'Não'
        } else if(this.downStok === 'downStok') {
          downSTK = 'Sim'
        }

        let custo = this.formCusto[1] == null || isNaN(this.formCusto[1]) ? 0 : this.formCusto[1]
        let lucro = this.formLucro[1] == null || isNaN(this.formLucro[1]) ? 0 : this.formLucro[1]

        if(this.editRow) {
        let aRec = parseFloat(this.formValor[1] - this.formEntrada[1])

          await this.updateData({
            table: 'GE_SELLS',
            fields: {
              'GE_CLIENT': this.client,
              'GE_LEAGUE': this.formLiga[1],
              'GE_TEAM': this.formTime[1],
              'GE_MODEL': this.formModelo[1],
              'GE_SIZE': this.formTamanho[1],
              'GE_GENDER': this.formSexo[1],
              'GE_QUANTITY': this.formQtdade[1],
              'GE_SALEVALUE': this.formValor[1],
              'GE_RECEIVEDVALUE': aRec,
              'GE_RECEIVABLE': this.formEntrada[1],
              'GE_COST': custo,
              'GE_GAIN': lucro,
              'GE_DATEPURCHASE': dtVenda,
              'GE_TRACKINGCODE': this.formRastreio[1],
              'GE_PURCHASERECEIVED': this.formEntrega[1],
              'GE_ENTERPRISE': this.enterprise,
              'GE_OBS': this.obs,
              'DELETED': ' ',
            },
            match: {
              'RECNO': this.recno
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });

          this.resetForm();
        } else {
          if (downSTK == 'Não') {
            for (let k = 1; k < this.formLiga.length; k++) {
              let aReceb = parseFloat(this.formValor[k] - this.formEntrada[k])

              await this.insertData({
                table: 'GE_SELLS',
                fields: {
                  'GE_CLIENT': this.client,
                  'GE_LEAGUE': this.formLiga[k],
                  'GE_TEAM': this.formTime[k],
                  'GE_MODEL': this.formModelo[k],
                  'GE_SIZE': this.formTamanho[k],
                  'GE_GENDER': this.formSexo[k],
                  'GE_QUANTITY': this.formQtdade[k],
                  'GE_SALEVALUE': this.formValor[k],
                  'GE_RECEIVEDVALUE': aReceb,
                  'GE_RECEIVABLE': this.formEntrada[k],
                  'GE_COST': (this.formCusto[k] == null || isNaN(this.formCusto[k]) ? 0 : this.formCusto[k]),
                  'GE_GAIN': (this.formLucro[k] == null || isNaN(this.formLucro[k]) ? 0 : this.formLucro[k]),
                  'GE_DATEPURCHASE': dtVenda,
                  'GE_TRACKINGCODE': this.formRastreio[k],
                  'GE_PURCHASERECEIVED': this.formEntrega[k],
                  'GE_DOWNSTOCK': downSTK,
                  'GE_ENTERPRISE': this.enterprise,
                  'GE_OBS': this.obs,
                  'GE_COD_CONTROL': this.codControl,
                  'DELETED': ' ',
                }
              })
            }
          } else {

            for (let w = 1; w < this.fEstoque.length; w++) {
              let aRecebe = parseFloat(this.fValor[w] - this.fEntrada[w])
              let reg = String(this.fEstoque[w]).split('|')

              let val = []
              reg.forEach(x => {
                val.push(x.replace('Q: ', '').trim())
              })

              await this.insertData({
                table: 'GE_SELLS',
                fields: {
                  'GE_CLIENT': this.client,
                  'GE_LEAGUE': val[0],
                  'GE_TEAM': val[1],
                  'GE_MODEL': val[2],
                  'GE_SIZE': val[3],
                  'GE_GENDER': val[4],
                  'GE_QUANTITY': this.fQtdade[w],
                  'GE_SALEVALUE': this.fValor[w],
                  'GE_RECEIVEDVALUE': aRecebe,
                  'GE_RECEIVABLE': this.fEntrada[w],
                  'GE_COST': (this.fCusto[w] == null || isNaN(this.fCusto[w]) ? 0 : this.fCusto[w]),
                  'GE_GAIN': (this.fLucro[w] == null || isNaN(this.fLucro[w]) ? 0 : this.fLucro[w]),
                  'GE_DATEPURCHASE': dtVenda,
                  'GE_TRACKINGCODE': '',
                  'GE_PURCHASERECEIVED': '',
                  'GE_DOWNSTOCK': downSTK,
                  'GE_ENTERPRISE': this.enterprise,
                  'GE_OBS': this.obs,
                  'GE_COD_CONTROL': this.codControl,
                  'GE_RECNO_WAREHOUSE': this.recnoSTK,
                  'DELETED': ' ',
                }
              });

            }
          }

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Venda inclusa com sucesso!'
          });
        }

        this.resetForm();
        this.modalSells = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'
        await this.updateData({
          table: 'GE_SELLS',
          fields: {
            'DELETED': '*',
            'GE_TRACKINGCODE': '',
            'GE_PURCHASERECEIVED': '',
          },
          match: {
            'RECNO': this.recno
          }
        })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.resetForm();
        this.modalSells = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let sells = await this.rpc({
        function: 'tSells',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      this.rows = sells

      //~> PARA ALIMENTAR OS CARDS DO RESUMO
      let summary = await this.rpc({
        function: 'resumo_tSells',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      this.resumoVendas = summary.map((sum) => {
        return {
          qtdade: parseInt(sum.qtdade),
          receita: isNaN(sum.receita) || sum.receita == null ? 0 : sum.receita,
          areceber: isNaN(sum.recebido) || sum.recebido == null ? 0 : sum.recebido, //~> Na tabela foi invertida a ordem de gravação
          recebido: isNaN(sum.areceber) || sum.areceber == null ? 0 : sum.areceber  //~> Na tabela foi invertida a ordem de gravação
        }
      });

      //~> PARA ALIMENTAR O COMBOBOX DAS VENDAS COM BAIXA NO ESTOQUE
      this.combobox = []
      this.optEstoque = []
      let combobox = await this.rpc({
        function: 'estoque_disp_combobox',
        enterprise: localStorage.getItem('enterprise')
      })

      this.combobox = combobox;
      combobox.forEach(box => {
        this.optEstoque.push(box.text_combobox)
      });

      //~> PARA ALIMENTAR O COMBOBOX DOS NOMES DOS CLIENTES
      this.comboboxClient = []
      this.optClient = []
      let comboCli = await this.rpc({
        function: 'clientes_ativos_combobox',
        enterprise: localStorage.getItem('enterprise')
      })

      this.comboboxClient = comboCli;
      comboCli.forEach(cli => {
        this.optClient.push(cli.nomes)
      });

      //~> PARA ALIMENTAR O COMBOBOX DAS CATEGORIAS
      let categoria = await categorys();

      this.categoria = categoria.map((cat) => {
        return cat.GE_DESCRIPTION
      })

      this.tipo = categoria.map(cat => {
        return {
          categoria: cat.GE_DESCRIPTION,
          tipo: cat.GE_TYPE
        }
      })

    },
    onSize(value) {

      console.log(value);

    },
    onDate() { //~> OK
      this.dt_ini = String(this.datePicker.from).replaceAll('/','');
      this.dt_fim = String(this.datePicker.to).replaceAll('/','');

      this.executeQuery();
    },
    onEditRow(_, row) { //~> OK

      if(row.codcontrol !== null) {
        // this.emiteComprovante = true
        this.codControl = row.codcontrol;
      } else {
        // this.emiteComprovante = false
        this.codControl = row.recno;
      }

      // console.log(this.codControl)

      if(row.downstok === 'Sim') {
        this.tipoVenda = 'VENDA REALIZADA COM BAIXA NO ESTOQUE'
        this.downStok = 'downStok'
        this.controlTracking = false
      } else {
        this.tipoVenda = 'VENDA REALIZADA SOB ENCOMENDA'
        this.downStok = 'toOrder'
        this.controlTracking = true
      }

      this.client = row.client
      this.dtSells = DateEnglish(row.dtpurchase)

      this.formLiga[1] = row.league
      this.formTime[1] = row.team
      this.formModelo[1] = row.model
      this.formSexo[1] = row.gender
      this.formTamanho[1] = row.sizes
      this.formQtdade[1] = row.qtd
      this.formValor[1] = parseFloat(row.sale).toFixed(2)
      this.formEntrada[1] = parseFloat(row.received).toFixed(2)
      this.formCusto[1] = parseFloat(row.costs).toFixed(2)
      this.formLucro[1] = parseFloat(row.gain).toFixed(2)
      this.formRastreio[1] = row.tracking
      this.formEntrega[1] = row.rec
      this.obs = row.obs
      this.recno = row.recno

      this.editRow = true;
      this.modalSells = true;
    },
    onTracking() { //~> OK
      this.rebuild = false;

      //~> ZERANDO PARA EVITAR DE GRAVAÇÕES ANTERIORES FICAREM SENDO GUARDADAS
      this.purchaseReceived = null;

      if(this.downStok !== 'toOrder' || this.downstok === null) {
        this.controlTracking = false;
      } else {
        this.controlTracking = true;
      }

      this.$nextTick(() => {
        this.rebuild = true;
      })
    },
    openProduct(index) {
      this.idxEstoque = index
      this.modalSelectProduct = true
    },
    onGetProduct(_, product) {

      this.fQtdade[this.idxEstoque] = 1
      const sugestPrice = parseFloat(product.preco).toFixed(2)
      const costUnit = parseFloat(product.custo).toFixed(2)
      this.sugestPrice[this.idxEstoque] = sugestPrice
      this.costUnit[this.idxEstoque] = costUnit
      this.fValor[this.idxEstoque] = sugestPrice
      this.fCusto[this.idxEstoque] = costUnit
      this.fEstoque[this.idxEstoque] = product.text_combobox
      this.maxQuant[this.idxEstoque] = parseInt(product.saldo)
      this.recnoSTK = product.recno

      this.costShirt(this.idxEstoque)

      this.modalSelectProduct = false
    },
    filterFn(val, update) {
      if (val === '') {
        val = ' '
        update(() => {
          this.options = this.optEstoque
        })
        return
      } else {
        update(() => {
          const needle = val.toLowerCase()
          this.options = this.optEstoque.filter(v => v.toLowerCase().indexOf(needle) > -1)
        })
      }
    },
    filterClient(val, update) {
      if (val === '') {
        val = ' '
        update(() => {
          this.optionsClient = this.optClient
        })
        return
      } else {
        update(() => {
          const needle = val.toLowerCase()
          this.optionsClient = this.optClient.filter(v => v.toLowerCase().indexOf(needle) > -1)
        })
      }
    },
    onAddEstoque() {
      this.ligaSTK = ''
      this.timeSTK = ''
      this.modeloSTK = ''
      this.tamanhoSTK = ''
      this.sexoSTK = ''
      this.qtdSTK = ''
      this.modalSTK = true;
    },
    onAddCliente() {
      this.client = ''
      this.newClient = ''
      this.cpf = ''
      this.phone = ''
      this.address = ''
      this.mail = ''
      this.insta = ''
      this.team = ''
      this.bday = ''
      this.modalClient = true;

      this.modalSells = false;
    },
    onCancelSTK() {
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.executeQuery();
        this.modalSTK = false;
      })
    },
    onCancelCLI() {
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.executeQuery();
        this.modalClient = false;
      })
    },
    async onSaveSTK() {
      await this.insertData({
        table: 'GE_WAREHOUSE',
        fields: {
          'GE_LEAGUE': this.ligaSTK,
          'GE_TEAM': this.timeSTK,
          'GE_MODEL': this.modeloSTK,
          'GE_SIZE': this.tamanhoSTK,
          'GE_GENDER': this.sexoSTK,
          'GE_QTD_ACQUIRED': this.qtdSTK,
          'GE_ENTERPRISE': this.enterprise,
          'DELETED': ' ',
        }
      })

      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Registro adicionado com sucesso!'
      });

      this.modalSTK = false;
      this.executeQuery();
    },
    async onSaveCLI() {
      await this.insertData({
        table: 'GE_CLIENTS',
        fields: {
          'GE_NAME': this.newClient,
          'GE_CPF': this.cpf,
          'GE_PHONE': this.phone,
          'GE_ADDRESS': this.address,
          'GE_MAIL': this.mail,
          'GE_INSTAGRAM': this.insta,
          'GE_TEAMHEART': this.team,
          'GE_BIRTHDAY': String(this.bday).replaceAll('-', ''),
          'GE_ENTERPRISE': this.enterprise,
          'DELETED': ' ',
        }
      })

      this.modalClient = false;
      this.executeQuery();
      this.client = this.newClient
      this.modalSells = true;
    },
    onChangeClient(val) {
      console.log(val);
    },
    setClient (val) {
      // this.fnClient = val
      // this.client = this.fnClient
      this.client = val

      console.log('fnClient',this.fnClient)
      console.log('client',this.client)
      console.log('val',val)
    },
    logCliente(val) {
      console.log(val);
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Vendas.csv");
      link.click();
    },
    async onRastreio() {

      console.log(this.formRastreio[1])
      const track = await rastreio(this.formRastreio[1])
      console.log(track);

    },
    onComprovante() {
      sessionStorage.setItem('infoComprovante', this.codControl)
      this.$router.push('/comprovante');
    }
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
</style>